<template>
  <Testimonial
    :author="author"
    :type="color"
    :src="imageUrl"
    :srcset="srcset"
    :alt="imageAlt"
  >
    {{ body }}
  </Testimonial>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import Testimonial from "~/components/Testimonial"

  export default {
    components: { Testimonial },
    mixins: [AlchemyElement],

    computed: {
      body() {
        return this.getValue("body")
      },
      author() {
        return this.getValue("author")
      },
      image() {
        return this.getIngredient("image") || {}
      },
      imageAlt() {
        return this.image.altText
      },
      imageUrl() {
        return this.image.value
      },
      srcset() {
        return this.image.srcset
      },
      color() {
        return this.getValue("color")
      },
    },
  }
</script>
